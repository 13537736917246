.welcome-text {
    padding: 10vh 0 30vh 0;
}

.welcome-button {
    padding-top: 20vh;
}

@media only screen and (max-width: 601px) {
    .welcome-text {
        padding: 1.5em 0 1.5em 0;
    }

    .welcome-button {
        padding-top: 2em;
    }
}
