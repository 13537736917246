﻿// OTHERS
.primary-bg {
    background-color: #fff;
}


.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:visited {
    color: #fff;
    background-color: $primary-color;
}


html {
    height: 100%;
    box-sizing: border-box;
}

body {
    position: relative;
    margin: 0;
    min-height: 100%;
    padding-bottom: 50px !important;
}

.no-padding {
    padding: 0 !important;
}

.dip-block {
    display: block;
}

.no-margin {
    margin: 0 !important;
}

div.navbar-custom nav {
    margin-bottom: 25px;
    box-shadow: none !important;

    border-bottom: 3px solid $primary-color;
    background-color: #fff;


    a {
        color: $secondary-font-color;
    }

    .brand-logo {
        position: relative;
        height: 100%;

        img {
            height: 100%;
        }
    }
}

.full-container {
    width: 100%;
}

.upper-text {
    text-transform: uppercase;
    font-weight: 500;
}

// INDEX PAGE
// Video customs
#video-description-section {
    padding-bottom: 52px !important;
}

.index-video {
    margin-top: 40px;
}

.promo {
    padding-bottom: 27px !important;
}

.promo i {
    margin: 40px 0;
    font-size: 7rem;
    display: block;
}

.promo p {
    font-size: 20px;
}

.part {
    padding: 100px 0 !important;
}

.promo-caption {
    font-size: 28px !important;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
}

.promo-color-1 {
    background-color: $secondary-color;
    color: $secondary-font-color;
}

.promo-color-2 {
    background-color: $primary-color;
}

.promo-1-text {
    padding: 40px 20px !important;
}

.promo-btn {
    margin-top: 10px;
}

.promo-caption {
    font-size: 26px;
}

.promo-text {
    font-size: $h5-fontsize;
}

.promo-3-image {
    margin-top: 80px;
}

.promo-3-footer {
    padding: 20px 0 !important;
}

.promo-3-button {
    padding: 5px 20px !important;
    font-size: 20px;
    height: auto !important;
    width: 100%;
}

.btn-red,
.btn-red:hover,
.btn-red:active,
.btn-red:visited {
    color: #fff;
    background-color: $primary-color;
}

.btn-white,
.btn-white:hover,
.btn-white:active,
.btn-white:visited {
    color: $primary-color;
    background-color: #fff;
}

.tipps-second-text {
    margin-top: 0 !important;
}

.card-title-bet {
    font-size: 40px !important;
    font-weight: 400 !important;
    text-shadow: 1px 1px 9px #000000;
}

.collapsible-header {
    font-size: 24px !important;
}

.collapsible-body p {
    font-size: 20px !important;
}

.card-content {
    max-height: 100% !important;
}

.footer {
    background-image: url('../images/footer.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#technogym {
    background-image: url('../images/technogym.jpg');
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    background-repeat: no-repeat;
}

#technogym {
    padding: 260px 0 !important;
}

#technogym a {
    font-weight: 100 !important;
}


.buy-section {
    padding: 120px 0 !important;
}

.paymentoptions {
    margin: 40px 0 !important;
}

.part2 {
    padding: 20px 10px !important;
}

.no-bottom-pad {
    padding-bottom: 0 !important;
}

footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}


.flip_container {
    perspective: 1000;
}

.flip_card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.5s linear;
}

.flip_container:hover .flip_card {
    transform: rotateY(180deg);
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.face.back {
    display: block;
    transform: rotateY(180deg);
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
}

.no-card-content {
    height: auto !important;
}

.no-card-content .card-image {
    max-height: 80% !important;
}

.no-card-content h5 {
    font-size: 18px !important;
}

.tipps-cards {
    position: relative;
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
}

.tipps-cards .inner {
    width: 4000px;
}

.tipps-cards .tipp {
    width: 400px;
    float: left;
}

p {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.leistungs-circle {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    line-height: 240px;
    background-color: $primary-color;
    text-align: center;
    margin: 120px auto 0px auto;
    cursor: pointer;
    -webkit-transition: all 0.7s ease;
    -moz-transition: all 0.7s ease;
    -ms-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.leistungs-circle:hover {
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -o-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.leistungs-circle-content {
    display: none;
}

.collapsible-body h5 {
    padding: 28px;
}


//     @media (max-width: 1500px) {
//     .button-collapse {
//         display: block !important;
//     }
// }
@media only screen and (max-width: 1505px) {
    nav a.button-collapse {
        // display: none;
    }

    .navbar-custom {
        ul>li>a {
            padding: 0 10px;
            margin: 0 5px;
        }
    }
}

@media only screen and (max-width: 1330px) {
    .navbar-custom {
        .button-collapse {
            display: block !important;
        }

        ul.right {
            display: none;
        }

        .brand-logo {
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }
}